import {
  trackAnalyticsEvent,
  EVENT_NAME_BREADCRUMB_CLICK,
} from "src/utils/track";

export const trackClick = (nextUrl, currentUrl, label) => {
  trackAnalyticsEvent(EVENT_NAME_BREADCRUMB_CLICK, {
    next_url: nextUrl,
    current_url: currentUrl,
    label,
  });
};
