import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  swiper: {
    width: "100%",
    height: "100%",
  },

  swiperSlide: {
    height: "auto",
  },

  bullet: {
    "&.swiper-pagination-bullet": {
      width: "6px",
      height: "6px",
      transform: "scale(1)",

      background: theme.palette.common.white,
      opacity: 0.5,
    },
  },
  activeBullet: {
    "&.swiper-pagination-bullet": {
      background: theme.palette.common.white,
      opacity: 1,
    },
  },
}));
