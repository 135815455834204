import NextLink from "next/link";

import MUILink from "@material-ui/core/Link";

const MuiNextLink = ({ href, children, ...rest }) => (
  <NextLink href={href} passHref>
    <MUILink {...rest}>{children}</MUILink>
  </NextLink>
);

export default MuiNextLink;
