import scrollIntoView from "scroll-into-view-if-needed";

const scrollToElementById = (elementId) => {
  const node = document.getElementById(elementId);
  if (node) {
    scrollIntoView(document.getElementById(elementId), {
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }
};

export default scrollToElementById;
