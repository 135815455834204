import React, { memo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
} from "@muchbetteradventures/components/dist/Icons";

import { useStyles } from "./styles";

const SlideButtons = ({ onNext, onPrev }) => {
  const classes = useStyles();

  return (
    <>
      <IconButton
        disableRipple
        className={clsx(
          classes.slideButton,
          classes.prevSlideButton,
          "show-on-hover"
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onPrev();
        }}
      >
        <ChevronLeft fontSize="inherit" />
      </IconButton>
      <IconButton
        disableRipple
        className={clsx(
          classes.slideButton,
          classes.nextSlideButton,
          "show-on-hover"
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onNext();
        }}
      >
        <ChevronRight fontSize="inherit" />
      </IconButton>
    </>
  );
};

SlideButtons.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default memo(SlideButtons);
