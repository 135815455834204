import { withStyles, makeStyles, Tooltip } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {},
  tooltipIcon: {
    display: "block",
    fontSize: "inherit",
  },
}));

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    padding: "13px 20px",
    borderRadius: "5px",
    backgroundColor: (props) => props.color,
  },
  arrow: {
    color: (props) => props.color,

    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
}))(Tooltip);
