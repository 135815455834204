import { memo } from "react";

import { Info } from "@muchbetteradventures/components/dist/Icons";

import { useTheme } from "@material-ui/core";
import { useStyles, StyledTooltip } from "./styles";

const Tooltip = ({ title = null, color = null, placement = "right" }) => {
  const classes = useStyles();
  const theme = useTheme();

  color = color || theme.palette.primary.main;

  return (
    <StyledTooltip
      title={title}
      placement={placement}
      arrow
      className={classes.root}
      color={color}
    >
      <div>
        <Info className={classes.tooltipIcon} htmlColor={color} />
      </div>
    </StyledTooltip>
  );
};

export default memo(Tooltip);
