const addTrailingSlash = (path) => {
  const [pathname, querystring] = path.split("?");

  const pathnameWithTrailingSlash =
    pathname.slice(-1) === "/" ? pathname : pathname.concat("/");

  return querystring
    ? `${pathnameWithTrailingSlash}?${querystring}`
    : pathnameWithTrailingSlash;
};

export { addTrailingSlash };
