import "swiper/css";
import "swiper/css/pagination";

import clsx from "clsx";
import React, { memo, useRef } from "react";
import { Photograph } from "src/components/Image";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { ButtonBase } from "@material-ui/core";

import { SlideButtons } from "./components";
import { useStyles } from "./styles";

const ImageGallery = ({
  images,
  imageSizes,
  onSlideChange,
  showButtons,
  onImageClick,
  loop = false,
  allowTouchMove = true,
  slidesPerView = 1,
  slidesPerGroup = 1,
  spaceBetween = 0,
  shouldShowImagePlaceholder = true,
}) => {
  const classes = useStyles();

  const swiper = useRef(null);

  const handleNext = () => {
    swiper.current.swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.current.swiper.slidePrev();
  };

  const handleImageClick = (image, idx) => {
    if (onImageClick) {
      onImageClick(image, idx);
    }
  };

  return (
    <>
      <Swiper
        modules={[Pagination]}
        allowTouchMove={allowTouchMove}
        pagination={{
          clickable: showButtons,
          bulletClass: clsx(classes.bullet, "swiper-pagination-bullet"),
          bulletActiveClass: classes.activeBullet,
          dynamicBullets: true,
        }}
        className={classes.swiper}
        ref={swiper}
        onSlideChange={onSlideChange}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        spaceBetween={spaceBetween}
        loop={loop}
      >
        {images.map((image, idx) => (
          <SwiperSlide
            className={classes.swiperSlide}
            key={image.key || image.url}
          >
            <ButtonBase
              style={{ position: "unset" }}
              onClick={() => handleImageClick(image, idx)}
              disabled={!Boolean(onImageClick)}
            >
              <Photograph
                alt=""
                className="swiper-lazy"
                src={image.url}
                sizes={imageSizes}
                shouldShowPlaceholder={shouldShowImagePlaceholder}
              />
            </ButtonBase>
          </SwiperSlide>
        ))}
      </Swiper>

      {showButtons && <SlideButtons onNext={handleNext} onPrev={handlePrev} />}
    </>
  );
};

export default memo(ImageGallery);
