import {
  getAndRemoveFromLocalStorage,
  setLocalStorage,
} from "src/utils/localStorage";

export const LOCAL_STORAGE_ALGOLIA_CONTEXT_KEY = "MBA__algoliaContext";

export const setAlgoliaQueryContext = (
  index,
  queryID,
  objectID,
  isShortlisted
) => {
  const params = {
    index,
    queryID,
    objectID,
  };

  if (typeof isShortlisted !== "undefined")
    params.isShortlisted = isShortlisted;

  return setLocalStorage(LOCAL_STORAGE_ALGOLIA_CONTEXT_KEY, params);
};

export const getAndRemoveAlgoliaQueryContext = () =>
  getAndRemoveFromLocalStorage(LOCAL_STORAGE_ALGOLIA_CONTEXT_KEY) || {};
