import { useRouter } from "next/router";
import { memo, useCallback } from "react";
import useIsMdUp from "src/hooks/useIsMdUp";

import { Box, Breadcrumbs as MuiBreadcrumbs } from "@material-ui/core";

import MuiNextLink from "../MuiNextLink";
import StructuredData from "../StructuredData";
import { trackClick } from "./tracking";

const schemaOrgMarkup = (paths) => {
  // See: https://schema.org/BreadcrumbList
  const baseURL = "https://www.muchbetteradventures.com";
  const scheemaOrgMarkup = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": `${baseURL}/`,
          name: "Home",
        },
      },
    ],
  };
  paths.forEach((element, idx) => {
    scheemaOrgMarkup.itemListElement.push({
      "@type": "ListItem",
      position: idx + 2,
      item: {
        "@id": `${baseURL}${element.url}`,
        name: element.label,
      },
    });
  });
  return scheemaOrgMarkup;
};

const Breadcrumbs = ({ paths }) => {
  const isMdUp = useIsMdUp();

  const router = useRouter();
  const makeClickHandler = useCallback(
    (nextUrl, label) => () => trackClick(nextUrl, router.asPath, label),
    [router.asPath]
  );

  return (
    <>
      <StructuredData
        name="breadcrumbs-json-ld"
        data={schemaOrgMarkup(paths)}
      />
      <Box zIndex={1} color="white">
        <MuiBreadcrumbs
          aria-label="breadcrumb"
          component="div"
          variant="h6"
          maxItems={isMdUp ? 10 : 3}
          itemsAfterCollapse={2}
          itemsBeforeCollapse={0}
          color="inherit"
        >
          <MuiNextLink href="/" color="inherit">
            Home
          </MuiNextLink>
          {paths.map(({ url, label }) => (
            <MuiNextLink
              key={url}
              href={url}
              color="inherit"
              title={label}
              onClick={makeClickHandler(url, label)}
            >
              {label.length > 20 ? `${label.slice(0, 18).trim()}...` : label}
            </MuiNextLink>
          ))}
        </MuiBreadcrumbs>
      </Box>
    </>
  );
};

export default memo(Breadcrumbs);
