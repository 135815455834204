import { useMediaQuery, useTheme } from "@material-ui/core";

export const useIsWidthUp = (breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
};

export const useScreenSize = () => {
  const isXs = useIsWidthUp("xs")
  const isSm = useIsWidthUp("sm")
  const isMd = useIsWidthUp("md")
  const isLg = useIsWidthUp("lg")
  const isXl = useIsWidthUp("xl")

  if (isXl) return "xl"
  if (isLg) return "lg"
  if (isMd) return "md"
  if (isSm) return "sm"
  if (isXs) return "xs"
  return "xs"
}

export const useIsWidthDown = (breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
};