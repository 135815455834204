import PropTypes from "prop-types";
import React from "react";

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ShortlistLoginRequiredDialog = ({ open, handleClose }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" disableTypography>
      <Typography variant="h4">Start your adventure</Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        To save and share your favourite adventures, you need to be a member,
        login or sign-up below
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" variant="text">
        Cancel
      </Button>
      <Button
        href={`/accounts/login/?next=${
          typeof document !== "undefined" ? document.location.href : ""
        }`}
        color="primary"
        variant="text"
      >
        Login
      </Button>
      <Button
        href={`/accounts/register/?next=${
          typeof document !== "undefined" ? document.location.href : ""
        }`}
        color="primary"
        autoFocus
        variant="text"
      >
        Sign Up
      </Button>
    </DialogActions>
  </Dialog>
);
ShortlistLoginRequiredDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

ShortlistLoginRequiredDialog.defaultProps = {
  open: false,
};

export default ShortlistLoginRequiredDialog;
