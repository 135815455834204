import clsx from "clsx";
import { memo } from "react";
import { useIsWidthDown, useIsWidthUp } from "src/hooks/useBreakpoint";
import useIsClientSide from "src/hooks/useIsClientSide";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return theme.breakpoints.keys.reduce((acc, breakpoint) => {
    acc[`hideDown_${breakpoint}`] = {
      [theme.breakpoints.down(breakpoint)]: {
        display: "none",
      },
    };

    acc[`hideUp_${breakpoint}`] = {
      [theme.breakpoints.up(breakpoint)]: {
        display: "none",
      },
    };

    return acc;
  }, {});
});

const DisplayOnScreenSize = ({
  children,
  hideUp,
  hideDown,
  preserveTree = false,
}) => {
  const styles = useStyles();

  const classes = [];
  if (hideUp) classes.push(styles[`hideUp_${hideUp}`]);
  if (hideDown) classes.push(styles[`hideDown_${hideDown}`]);

  const matchesHideUp = useIsWidthUp(hideUp);
  const matchesHideDown = useIsWidthDown(hideDown);
  const isClientSide = useIsClientSide();

  // Since we can't determine the screen size on the server side
  // we initially need to render every possible tree and then let css show/hide based on media queries.
  //
  // Once we're on the client size, we can clean up any hidden trees and let javascript take over.
  const shouldHideTree =
    isClientSide && !preserveTree && (matchesHideUp || matchesHideDown);

  return shouldHideTree ? null : (
    <div className={clsx(classes)}>{children}</div>
  );
};

export default memo(DisplayOnScreenSize);
