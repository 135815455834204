import React from "react";
import { connect, useSelector } from "react-redux";
import { getFxState } from "src/state/selectors";

const ellipsis = String.fromCharCode("0x2026");

class UserPrice extends React.Component {
  priceString() {
    const {
      rates,
      value,
      userCurrencyCode,
      currencyCode,
      round,
      percentage = 100,
    } = this.props;

    return makePriceString(
      rates,
      value,
      userCurrencyCode,
      currencyCode,
      round,
      percentage
    );
  }

  ready() {
    const { userCurrencyCode, rates } = this.props;
    return isReady(userCurrencyCode, rates);
  }

  render() {
    return this.ready() ? <>{this.priceString()}</> : <>{ellipsis}</>;
  }
}

const mapStateToProps = ({ fx }) => ({
  ...fx,
});

export default connect(mapStateToProps)(UserPrice);

// Helpers + Hooks
// -----

export const useUserPriceString = (
  value,
  currencyCode,
  round = false,
  percentage = 100
) => {
  const fxState = useSelector(getFxState);

  const isFxReady = fxState && isReady(fxState.userCurrencyCode, fxState.rates);

  return isFxReady
    ? makePriceString(
        fxState.rates,
        value,
        fxState.userCurrencyCode,
        currencyCode,
        round,
        percentage
      )
    : ellipsis;
};

export const makePriceString = (
  rates,
  value,
  userCurrencyCode,
  currencyCode,
  round,
  percentage = 100
) => {
  let displayValue = value;
  let displayCurrency = currencyCode;

  try {
    displayValue = buy(rates, value, userCurrencyCode, currencyCode);
    displayCurrency = userCurrencyCode;
  } catch (err) {
    // pass
  }

  let renderedPrice;
  if (percentage === 100) renderedPrice = displayValue;
  else renderedPrice = (displayValue.toFixed(2) * percentage) / 100.0;

  if (round) {
    renderedPrice = Math.round(renderedPrice);
  }

  renderedPrice = renderedPrice.toLocaleString("en", {
    currency: displayCurrency,
    style: "currency",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  if (round) {
    return renderedPrice.slice(0, -3);
  }
  return renderedPrice;
};

const buy = (rates, amount, from, to) => {
  const rate = rates[from][to];
  return amount / rate;
};

const isReady = (userCurrencyCode, rates) => userCurrencyCode && rates;
