import clsx from "clsx";
import PropTypes from "prop-types";
import { useCallback, useContext, useState } from "react";
import { connect } from "react-redux";
import ProductHitTrackingContext from "src/context/tracking/productHit";
import ProductPageTrackingContext from "src/context/tracking/productPage";
import {
  EVENT_NAME_PRODUCT_ADDED_TO_WISHLIST,
  EVENT_NAME_PRODUCT_REMOVED_FROM_WISHLIST,
  trackAnalyticsEvent,
} from "src/utils/track";

import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { grey, red } from "@material-ui/core/colors";
import {
  HeartFilled,
  HeartOutline,
} from "@muchbetteradventures/components/dist/Icons";

import {
  addProductToShortlist,
  createShortlist,
  fetchShortlistProducts,
  fetchShortlists,
  removeProductFromShortlist,
} from "../../state/actions";
import ShortlistLoginRequiredDialog from "./ShortlistLoginRequiredDialog";

const useStyles = makeStyles(() => ({
  noBackgroundHover: {
    ["&:hover"]: {
      backgroundColor: "transparent",
    },
  },
}));

const ShortlistProduct = ({
  user: { id: userId },
  productId,
  shortlist,
  isShortlisted,
  addProduct,
  removeProduct,
  size,
  loadingColor = grey[400],
  notShortlistedColor = grey[400],
  shortlistedColor = red[500],
  ...rest
}) => {
  const classes = useStyles();

  const [showLoginDialog, setShowLoginDialog] = useState(false);

  const pageTrackingContext = useContext(ProductPageTrackingContext);
  const hitTrackingContext = useContext(ProductHitTrackingContext);

  const handleAddProductClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      trackAnalyticsEvent(EVENT_NAME_PRODUCT_ADDED_TO_WISHLIST, {
        ...pageTrackingContext,
        ...hitTrackingContext,
      });
      addProduct(productId, shortlist);
    },
    [addProduct, productId, shortlist, pageTrackingContext, hitTrackingContext]
  );

  const handleRemoveProductClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      trackAnalyticsEvent(EVENT_NAME_PRODUCT_REMOVED_FROM_WISHLIST, {
        ...pageTrackingContext,
        ...hitTrackingContext,
      });
      removeProduct(productId, shortlist);
    },
    [
      removeProduct,
      productId,
      shortlist,
      pageTrackingContext,
      hitTrackingContext,
    ]
  );

  // loading
  if (shortlist.loading) {
    return (
      <IconButton
        disabled
        size={size}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...rest}
      >
        <HeartFilled htmlColor={loadingColor} />
      </IconButton>
    );
  }

  // not logged in
  if (!userId) {
    return (
      <>
        <IconButton
          size={size}
          className={clsx({ [classes.noBackgroundHover]: size === "small" })}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowLoginDialog(true);
          }}
          {...rest}
        >
          <HeartOutline htmlColor={notShortlistedColor} />
        </IconButton>
        <ShortlistLoginRequiredDialog
          open={showLoginDialog}
          handleClose={() => setShowLoginDialog(false)}
        />
      </>
    );
  }

  // logged in
  return isShortlisted(productId) ? (
    <IconButton
      size={size}
      onClick={handleRemoveProductClick}
      className={clsx({ [classes.noBackgroundHover]: size === "small" })}
      {...rest}
    >
      <HeartFilled htmlColor={shortlistedColor} />
    </IconButton>
  ) : (
    <IconButton
      size={size}
      onClick={handleAddProductClick}
      className={clsx({ [classes.noBackgroundHover]: size === "small" })}
      {...rest}
    >
      <HeartOutline htmlColor={notShortlistedColor} />
    </IconButton>
  );
};

ShortlistProduct.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  shortlist: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  isShortlisted: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

ShortlistProduct.defaultProps = {
  user: { id: null },
};

const mapStateToProps = ({ user, shortlist }) => ({
  shortlist,
  user,
  isShortlisted: (productId) =>
    Boolean(shortlist.products.find((k) => k.product === productId)),
});

const mapDispatchToProps = (dispatch) => ({
  addProduct: (productId, shortlist) => {
    const shortlistId = shortlist.shortlists.length
      ? shortlist.shortlists[0].id
      : null;

    if (shortlistId) {
      dispatch(addProductToShortlist(productId, shortlistId))
        .then(() => dispatch(fetchShortlistProducts(shortlistId)))
        .catch(() => {});
    } else {
      // no shortlist, so we need to create one
      let newShortlistId;
      dispatch(createShortlist())
        .then(({ action }) => {
          newShortlistId = action.payload.data.id;
          return dispatch(addProductToShortlist(productId, newShortlistId));
        })
        .then(() => dispatch(fetchShortlists()))
        .then(() => dispatch(fetchShortlistProducts(newShortlistId)))
        .catch(() => {});
    }
  },
  removeProduct: (productId, shortlist) => {
    const shortlistProduct = shortlist.products.find(
      (k) => k.product === productId
    );
    dispatch(removeProductFromShortlist(shortlistProduct)).then(() =>
      dispatch(fetchShortlistProducts(shortlistProduct.shortlist))
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistProduct);
