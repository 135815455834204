import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  slideButton: {
    background: "white",
    opacity: 0.5,
    position: "absolute",
    zIndex: 999,
    top: "50%",
    transform: "translate(0%, -50%)",
    transition: "opacity 0.1s ease-in-out",

    "&:hover": {
      background: "white",
      opacity: "1 !important",
    },
    fontSize: 14,
  },
  prevSlideButton: {
    left: theme.spacing(2),
  },
  nextSlideButton: {
    right: theme.spacing(2),
  },
}));
