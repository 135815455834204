import clsx from "clsx";
import { memo } from "react";
import { Tooltip } from "src/components/shared";

import {
  Box,
  ButtonBase,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import * as Icons from "@muchbetteradventures/components/dist/Icons";

import { kebabToCamel } from "../../utils/camel-kebab";
import scrollToElementById from "../../utils/scroltoelementbyid";
import Markdown from "../Markdown";

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    flexGrow: 0,
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  markdownWrapper: {
    "& *:last-child": {
      marginBottom: 0,
    },
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    paddingTop: 4,
  },
  button: {
    display: "block",
    overflow: "hidden",
  },
  valueWrapper: {
    display: "flex",
    alignItems: "center",
  },
  value: {
    marginRight: "5px",
  },
});

const handleButtonClick = (link) => (e) => {
  if (link.startsWith("#")) {
    e.preventDefault();
    scrollToElementById(link.substr(1));
  }
};

const Highlight = ({
  classes,
  label = null,
  labelVariant = "body1",
  labelStrong = true,
  value = null,
  valueVariant = "body1",
  subtext = null,
  subtextVariant = "body1",
  tooltipText = null,
  icon = null,
  children = null,
  isHtml = false,
  link = null,
  iconColor = null,
  noWrap = false,
}) => {
  const Icon = icon ? Icons[icon] || Icons[kebabToCamel(`-${icon}`)] : null;

  const theme = useTheme();

  const contents = (
    <div className={classes.wrapper}>
      {Icon && (
        <Icon
          className={classes.icon}
          htmlColor={iconColor || theme.palette.text.primary}
        />
      )}
      <Box className={classes.content}>
        <Typography color="inherit" variant={labelVariant} noWrap={noWrap}>
          {labelStrong && labelVariant === "body1" ? (
            <strong>{label}</strong>
          ) : (
            label
          )}
        </Typography>

        {(value || tooltipText) && (
          <Box className={classes.valueWrapper}>
            <Box
              className={clsx(classes.value, {
                [classes.markdownWrapper]: isHtml,
              })}
            >
              {isHtml ? (
                <Markdown serif={false} text={value} />
              ) : (
                <Typography
                  color="inherit"
                  variant={valueVariant}
                  noWrap={noWrap}
                >
                  {value}
                </Typography>
              )}
            </Box>
            {tooltipText && <Tooltip title={tooltipText} />}
          </Box>
        )}

        {subtext && (
          <Typography color="inherit" variant={subtextVariant} noWrap={noWrap}>
            {subtext}
          </Typography>
        )}

        {children}
      </Box>
    </div>
  );
  return link ? (
    <ButtonBase
      className={classes.button}
      href={link}
      onClick={handleButtonClick(link)}
    >
      {contents}
    </ButtonBase>
  ) : (
    <Box className={classes.button}>{contents}</Box>
  );
};

export default withStyles(styles)(memo(Highlight));
